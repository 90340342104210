<template lang="pug">
  table.settings-table
    thead.settings-header
      tr
        th.table-item.header
          span {{ $t("plan_settings.basic_settings.templates.table.template_name") }}
        th.table-item.header
          span {{ $t("plan_settings.basic_settings.templates.table.creation_date") }}
        th.table-item.header
          span {{ $t("plan_settings.basic_settings.templates.table.creation_initiator") }}
        th.table-item.header
          span {{ $t("plan_settings.basic_settings.templates.table.last_updated") }}
        th.table-item.header
          span {{ $t("plan_settings.basic_settings.templates.table.update_initiator") }}
        th.table-item.header
    tbody
      TemplatesItem(
        v-for="(template, index) in templates"
        :key="template.id"
        :template="template"
        :index="index"
        @delete="deleteTemplate"
      )
</template>

<script>
  export default {
    props: {
      templates: {
        type: Array,
        default: () => new Array()
      }
    },

    components: {
      TemplatesItem: () => import("./TemplatesItem")
    },

    methods: {
      deleteTemplate(id) {
        this.$emit("delete", id)
      }
    }
  }
</script>

<style lang="sass">
  @import "@/assets/styles/variables.sass"

  .settings-table
    margin-bottom: 20px
    width: 100%

    .settings-header
      background: $default-gray-light

      .header
        padding: 7px 10px
        font-size: 0.8rem
        font-weight: 700
        color: $default-black

    .table-item
      border-bottom: $element-border
      padding: 7px 10px
</style>
